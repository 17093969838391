import React from "react"
import { Link } from "gatsby"

import AdminLayout from "../../components/adminPanelLayout"
import DailyCalendarComponent from "../../components/adminDashboard/DailyCalendarComponent"
import SEO from "../../components/seo"
import styled from "styled-components"

const ConfigurePageTitle = styled.h2`
  text-align: center;
  font-weight: 400;
`
const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <ConfigurePageTitle>Aktualności</ConfigurePageTitle>
    <DailyCalendarComponent />
  </AdminLayout>
)

export default AdminPanel
